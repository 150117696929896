import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css'; // Import the CSS file for styling
import Navbar from './Navbar';
const HomePage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const correctCode = '1D3DA9V1';

  const handleLookUp = () => {
    if (code === correctCode && isCaptchaChecked) {
      navigate('/result');
    } else {
      if (!isCaptchaChecked) {
        setErrorMessage('Please verify the CAPTCHA.');
      } else {
        setErrorMessage('The code is not correct. Please try again.');
      }
    }
  };

  const handleInputChange = (e) => {
    setCode(e.target.value);
    if (errorMessage) {
      setErrorMessage(''); // Clear error message when user starts typing
    }
  };

  const handleCaptchaChange = (e) => {
    setIsCaptchaChecked(e.target.checked);
    if (errorMessage && e.target.checked) {
      setErrorMessage(''); // Clear error message when captcha is checked
    }
  };

  return (
    <div>
    <Navbar />
    <div className="home-page">
      <div className="background-overlay">
        <div className="content">
          <div className="float">
            <p className='open-sans'>Trace your</p>
            <h1 className='libre-baskerville-bold'>Authenticity</h1>
          </div>
          <div className="search-box">
            <input 
              type="text" 
              placeholder="Enter your unique code" 
              value={code}
              onChange={handleInputChange}
            />
            <button onClick={handleLookUp}>Look up</button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="recaptcha">
            <input 
              type="checkbox" 
              id="recaptcha" 
              checked={isCaptchaChecked}
              onChange={handleCaptchaChange}
            />
            <label htmlFor="recaptcha">I'm not a robot</label>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default HomePage;
import React, { useState } from 'react';
import './FreeTable.css';

const FreeTable = () => {
  const [rows, setRows] = useState([
    { topic: 'Text line', data: 'Text line' }, // Initial row
  ]);

  // Function to handle adding a new row
  const addRow = () => {
    setRows([...rows, { topic: '', data: '' }]);
  };

  // Function to handle deleting a row
  const deleteRow = (index) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  // Function to handle editing a row
  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  return (
    <div className="free-table-container">
      {/* <h3>Free Table</h3> */}
     
      <table>
        <thead>
          <tr>
            <th>Topic</th>
            <th>Data</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={row.topic}
                  onChange={(e) => handleChange(index, 'topic', e.target.value)}
                  placeholder="Enter topic"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.data}
                  onChange={(e) => handleChange(index, 'data', e.target.value)}
                  placeholder="Enter data"
                />
              </td>
              <td>
                <button className="edit-btn">Edit</button>
                <button className="delete-btn" onClick={() => deleteRow(index)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br/>
      <button className="add-btn" onClick={addRow}>Add Data</button>
    </div>
  );
};

export default FreeTable;

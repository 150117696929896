import React, { useState } from 'react';
import './AddBigPicture.css';

const AddBigPicture = () => {
  const [title, setTitle] = useState('');
  const [picture, setPicture] = useState(null);

  // Handle file change
  const handleFileChange = (event) => {
    setPicture(event.target.files[0]);
  };

  return (
    <div className="add-big-picture-container">

      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 1</label>
        <input
          type="file"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default AddBigPicture;

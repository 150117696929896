import React, { useState } from 'react';
import './AddSmallPicture.css';

const AddSmallPicture = () => {
  const [title, setTitle] = useState('');
  const [pictures, setPictures] = useState({
    picture1: null,
    picture2: null,
    picture3: null,
    picture4: null,
  });

  // Handle file change
  const handleFileChange = (event, pictureNumber) => {
    setPictures({
      ...pictures,
      [pictureNumber]: event.target.files[0],
    });
  };

  return (
    <div className="add-small-picture-container">

      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 1</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture1')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 2</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture2')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 3</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture3')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 4</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture4')}
        />
      </div>
    </div>
  );
};

export default AddSmallPicture;

import React from 'react';

const Maker = () => {
  return (
    <div>
      <h2>Maker</h2>
      {/* Add your content for Maker section */}
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Name</th>
            <th>Step</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((_, index) => (
            <tr key={index}>
              <td>Text line</td>
              <td>Text line</td>
              <td>Text line</td>
              <td className="action-buttons">
                <button className="edit-btn">Edit</button>
                <button className="delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Maker;

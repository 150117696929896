import React, { useState } from 'react';
import './Navbar.css'; // Import the CSS file for styling
import smileygem from '../image/smiley_gem_icon.png'

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={smileygem} alt="Smiley Gems Logo" />
        {/* <span>Smiley Gems</span> */}
      </div>
      {/* <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
        <li><a href="#home">Home</a></li>
        <li><a href="#products">Products</a></li>
        <li><a href="#resources">Resources</a></li>
        <li><a href="#pricing">Pricing</a></li>
      </ul>
      <div className="menu-toggle" onClick={toggleMenu}>
        ☰
      </div> */}
    </nav>
  );
};

export default Navbar;
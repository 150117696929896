import React, { useState } from 'react';
import './CustomsForm.css';
import FreeTable from './FreeTable';
import AddCertificate from './AddCertificate'; 
import AddMaker from './AddMaker';
import AddSmallPicture from './AddSmallPicture'; 
import AddBigPicture from './AddBigPicture';


const CustomsForm = () => {
  // Manage the dynamic sections
  const [sections, setSections] = useState([]);

  const addSection = (type) => {
    setSections([...sections, type]);
  };

  const removeSection = (index) => {
    const newSections = sections.filter((_, i) => i !== index);
    setSections(newSections);
  };

  const [formData, setFormData] = useState({
    numberId: '',
    name: '',
    model: '',
    category: '',
    company: '',
    picture1: null,
    picture2: null,
    picture3: null,
    picture4: null,
  });

  // Handle change for text inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file upload change
  const handleFileChange = (event, pictureNumber) => {
    setFormData({ ...formData, [pictureNumber]: event.target.files[0] });
  };

  return (
    <div className="basic-section-container ">
      <h2>Create customs product</h2>

      {/* Basic Section */}
      <div className="form-section">
       
        <div className="form-group">
        <label>Number ID*</label>
        <input
          type="text"
          name="numberId"
          value={formData.numberId}
          onChange={handleInputChange}
          placeholder="Enter Number ID"
          required
        />
      </div>

      <div className="form-group">
        <label>Name*</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter Name"
          required
        />
      </div>

      <div className="form-group section-selector">
        <label>Model</label>
      
        <select
          name="model"
          value={formData.model}
          onChange={handleInputChange}
        >
          <option value="">Please select</option>
          <option value="Model1">Model 1</option>
          <option value="Model2">Model 2</option>
          <option value="Model3">Model 3</option>
        </select>

        <label>Category</label>
        
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        >
          <option value="">Please select</option>
          <option value="Category1">Category 1</option>
          <option value="Category2">Category 2</option>
          <option value="Category3">Category 3</option>
        </select>

        <label>Company</label>
     
        <select
          name="company"
          value={formData.company}
          onChange={handleInputChange}
        >
          <option value="">Please select</option>
          <option value="Company1">Company 1</option>
          <option value="Company2">Company 2</option>
          <option value="Company3">Company 3</option>
        </select>
      </div>

      <div className="form-group">
        <label>Upload Picture 1</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture1')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 2</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture2')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 3</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture3')}
        />
      </div>

      <div className="form-group">
        <label>Upload Picture 4</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, 'picture4')}
        />
      </div>
        {/* Add more fields as needed */}
      </div>

      {/* Optional Sections */}
      {sections.map((section, index) => (
        <div key={index} className="form-section">
          {section === 'freeTable' && (
            <>
              <h3>Free Table</h3>
              <FreeTable />
              <button className="remove-btn" onClick={() => removeSection(index)}>Remove</button>
              {/* Free table inputs */}
              {/* Render 8 rows max */}
            </>
          )}
          {section === 'certificate' && (
            <>
              <h3>Add Certificate</h3>
              <AddCertificate />
              <button className="remove-btn" onClick={() => removeSection(index)}>Remove</button>
              {/* Certificate inputs */}
            </>
          )}
          {section === 'maker' && (
            <>
              <h3>Select Maker</h3>
              <AddMaker />
              <button className="remove-btn" onClick={() => removeSection(index)}>Remove</button>
              {/* Maker inputs */}
            </>
          )}
          {section === 'smallPicture' && (
            <>
              <h3>Add Small Picture</h3>
              <AddSmallPicture />
              <button className="remove-btn" onClick={() => removeSection(index)}>Remove</button>
              {/* Small picture inputs */}
            </>
          )}
          {section === 'bigPicture' && (
            <>
              <h3>Add Big Picture</h3>
              <AddBigPicture />
              <button className="remove-btn" onClick={() => removeSection(index)}>Remove</button>
              {/* Big picture inputs */}
            </>
          )}
        </div>
      ))}

      {/* Section Select */}
      <div className="section-selector">
        <select onChange={(e) => addSection(e.target.value)}>
          <option value="">Please select</option>
          <option value="freeTable">Free Table Max 8 Rows</option>
          <option value="certificate">Add Certificate</option>
          <option value="maker">Select Maker</option>
          <option value="smallPicture">Add Small Picture Max 4</option>
          <option value="bigPicture">Add Big Picture</option>
        </select>
        <button>Add Section</button>
      </div>
    </div>
  );
};

export default CustomsForm;

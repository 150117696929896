import React, { useState } from 'react';
import './CreateLots.css';

const CreateLots = () => {
  const [formData, setFormData] = useState({
    inputText: '',
    number: '0001',
    increment: 3,
    sameName: '',
    model: '',
    category: '',
    company: '',
    picture1: null,
    picture2: null,
    picture3: null,
    picture4: null,
  });

  // Handle change for text inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file upload change
  const handleFileChange = (event, pictureNumber) => {
    setFormData({ ...formData, [pictureNumber]: event.target.files[0] });
  };

  // Handle form submission (you can integrate with your API)
  const handleSubmit = () => {
    // Logic for form submission, such as sending data to an API
    console.log(formData);
  };

  return (
    <div className="create-lots-container">
      <h3>Create Running Number</h3>
      <p>ตัวอักษรเริ่มต้นเพื่อสร้างชุด ID สินค้า และกำหนดจำนวนสูงสุดของ Lot นี้ จากนั้นเพิ่มข้อมูลทุกอันจะเหมือนกัน</p>

      <div className="form-row">
        <div className="form-group">
          <label>Input fix text</label>
          <input
            type="text"
            name="inputText"
            value={formData.inputText}
            onChange={handleInputChange}
            placeholder="Enter text"
          />
        </div>

        <div className="form-group">
          <label>Number</label>
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
            placeholder="0001"
          />
        </div>

        <div className="form-group">
          <label>Increment</label>
          <input
            type="number"
            name="increment"
            value={formData.increment}
            onChange={handleInputChange}
            placeholder="3"
          />
        </div>
      </div>

      <div className="form-group">
        <label>Same Name</label>
        <input
          type="text"
          name="sameName"
          value={formData.sameName}
          onChange={handleInputChange}
          placeholder="Enter same name"
        />
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Model</label>
          <select name="model" value={formData.model} onChange={handleInputChange}>
            <option value="">Please select</option>
            <option value="Model1">Model 1</option>
            <option value="Model2">Model 2</option>
          </select>
        </div>

        <div className="form-group">
          <label>Category</label>
          <select name="category" value={formData.category} onChange={handleInputChange}>
            <option value="">Please select</option>
            <option value="Category1">Category 1</option>
            <option value="Category2">Category 2</option>
          </select>
        </div>

        <div className="form-group">
          <label>Company</label>
          <select name="company" value={formData.company} onChange={handleInputChange}>
            <option value="">Please select</option>
            <option value="Company1">Company 1</option>
            <option value="Company2">Company 2</option>
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Upload Picture 1</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'picture1')} />
        </div>

        <div className="form-group">
          <label>Upload Picture 2</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'picture2')} />
        </div>

        <div className="form-group">
          <label>Upload Picture 3</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'picture3')} />
        </div>

        <div className="form-group">
          <label>Upload Picture 4</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'picture4')} />
        </div>
      </div>

      <button className="submit-btn" onClick={handleSubmit}>Generate</button>
    </div>
  );
};

export default CreateLots;

import React from 'react';
import './ListCustoms.css';

const ListCustoms = () => {
  // Sample data for the table
  const customsList = [
    { id: 'Text line', name: 'Text line', model: 'Text line', category: 'Text line', title: 'Text line' },
    { id: 'Text line', name: 'Text line', model: 'Text line', category: 'Text line', title: 'Text line' },
    { id: 'Text line', name: 'Text line', model: 'Text line', category: 'Text line', title: 'Text line' },
    { id: 'Text line', name: 'Text line', model: 'Text line', category: 'Text line', title: 'Text line' },
    // Add more data as necessary
  ];

  return (
    <div className="list-customs-container">
      <h3>Table</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Model</th>
            <th>Category</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {customsList.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.model}</td>
              <td>{item.category}</td>
              <td>{item.title}</td>
              <td>
                <button className="publish-btn">Publish</button>
                <button className="edit-btn">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListCustoms;

import React from 'react';
import CustomsForm from './CustomsForm';  // Ensure the path is correct based on your project structure

const Customs = () => {
  return (
    <div className="customs-container">
      <CustomsForm />
    </div>
  );
};

export default Customs;

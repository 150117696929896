import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './component/Homepage';
import MainResult from './component/Mainresult';
import BackOfficeLayout from './component/Backoffice/BackOfficeLayout';
import Category from './component/Backoffice/Category';
import Model from './component/Backoffice/Model';
import Maker from './component/Backoffice/Maker';
import Customs from './component/Backoffice/Customs';
import Lots from './component/Backoffice/Lots';
import CreateLots from './component/Backoffice/CreateLots';
import ListCustoms from './component/Backoffice/ListCustoms';
import Listlot from './component/Backoffice/Listlot';
function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/result" element={<MainResult />} />
        </Routes>
        <BackOfficeLayout>
            <Routes>
              <Route path="/BackOffice/Category" element={<Category />} />
              <Route path="/BackOffice/Model" element={<Model />} />
              <Route path="/BackOffice/Maker" element={<Maker />} />
              <Route path="/BackOffice/Create/Customs" element={<Customs />} />
              <Route path="/BackOffice/Create/Lots" element={<CreateLots  />} />
              <Route path="/BackOffice/List/Customs" element={<ListCustoms />} />
              <Route path="/BackOffice/List/Lots" element={<Listlot />} />
            </Routes>
        </BackOfficeLayout>
      </div>
    </Router>
  );
}

export default App;

import React, { Suspense } from 'react';
import './Mainresult.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import Navbar from './Navbar';
function Model() {
    const fbx = useLoader(FBXLoader, '/3dmodel/ring1.fbx');
  
    // Adjust position and scale
    fbx.position.set(0, -1, 0);
    fbx.scale.set(1, 1, 1);
  
    return <primitive object={fbx} />;
  }

function MainResult() {
  return (
    <div>
    <Navbar />
    <div className="authenticity-traceability">
      <h1 style={{marginTop:"0px"}}>Authenticity Traceability</h1>
      <div className="product-info">
        <p>Categories: Rings</p>
        <p>Model: Eternal Love</p>
        <p>Product ID: 040380009V001</p>
        <p>Lot No: 1020</p>
        <p>Purchased Date: 24/12/2023</p>
      </div>
      <div className="model-viewer">
       {/* <Canvas>
       
        <perspectiveCamera position={[1, 1, 1]} />
        

        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={1} />

        <Suspense >
          <Model />
          <OrbitControls />
        </Suspense>
      </Canvas> */}
      <div class="sketchfab-embed-wrapper"> 
        <iframe title="Doji Diamond Ring" frameborder="0"  src="https://sketchfab.com/models/297133c25c4b4d62b3237b05f800e323/embed"> 
        </iframe> 
      </div>
        
        <div className="model-images">
          <img src="https://www.shutterstock.com/image-illustration/3d-rendering-diamond-ring-on-600nw-1595366245.jpg" alt="Ring View 1" />
          <img src="https://i.pinimg.com/originals/e4/da/e4/e4dae4b2aa88ba4ee8b2e1de08820ec0.jpg" alt="Ring View 2" />
        </div>
      </div>

      <div className="table-section">
        <h2>วัสดุตัวเรือน</h2>
        <table>
          <tbody>
            <tr>
              <td>Parts</td>
              <td>Information</td>
            </tr>
            <tr>
              <td>วัสดุ</td>
              <td>GOLD</td>
            </tr>
            <tr>
              <td>ความบริสุทธิ์ (Purity)</td>
              <td>18K</td>
            </tr>
            <tr>
              <td>น้ำหนัก (Weight)</td>
              <td>3.8 Gram</td>
            </tr>
            <tr>
              <td>แหล่งผลิต (Origin)</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-section">
        <h2>อัญมณีเม็ดหลัก</h2>
        <table>
          <tbody>
            <tr>
              <td>Parts</td>
              <td>Information</td>
            </tr>
            <tr>
              <td>Gemstone</td>
              <td>Diamond</td>
            </tr>
            <tr>
              <td>Carat Weight</td>
              <td>0.40 Carat</td>
            </tr>
            <tr>
              <td>Certificate</td>
              <td>GIA</td>
            </tr>
            <tr>
              <td>Color</td>
              <td>G</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>VVS1</td>
            </tr>
            <tr>
              <td>Cut Grade</td>
              <td>Excellent</td>
            </tr>
            <tr>
              <td>Shape</td>
              <td>Round Brilliant</td>
            </tr>
            <tr>
              <td>Source</td>
              <td>Chantaburi</td>
            </tr>
            <tr>
              <td>GIA Certificate</td>
              <td><a href="https://www.gia.edu/report-check?reportno=5202987259">5202987259</a></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-section">
        <h2>อัญมณีรอบวง</h2>
        <table>
          <tbody>
            <tr>
              <td>Parts</td>
              <td>Information</td>
            </tr>
            <tr>
              <td>Gemstone</td>
              <td>Diamond</td>
            </tr>
            <tr>
              <td>Total Weight</td>
              <td>0.20 Carat</td>
            </tr>
            <tr>
              <td>Color</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>VVS1</td>
            </tr>
            <tr>
              <td>Cut Grade</td>
              <td>Excellent</td>
            </tr>
            <tr>
              <td>Shape</td>
              <td>Round Brilliant</td>
            </tr>
            <tr>
              <td>Source</td>
              <td>Chantaburi</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="handmade-section">
        <h2>ช่างฝีมือ</h2>
        <img src="https://images.altv.tv/images/gallery-images/7ssjahqF5.jpg/cover1280w1280h/image.jpg" alt="Craftsperson" />
        <p class="bolded">นาย ทองดี มีทรัพย์</p>
        <p>
        ประสบการณ์และความเชี่ยวชาญ:
        ประสบการณ์ในอุตสาหกรรมเครื่องประดับ: 25 ปี
        ความเชี่ยวชาญ:การออกแบบเครื่องประดับ, 
        การเจียระไนเพชร, การตั้งเพชรและพลอย
        </p>
      </div>

      <div className="design-story">
        <h2>แรงบันดาลใจจากธรรมชาติ:</h2>
        <li><span class="bolded">ความงดงามของน้ำตก</span>: การที่น้ำตกเคลื่อนที่อย่างราบรื่นและต่อเนื่อง ผสมผสานกับการเล่นแสงของหยดน้ำที่ตกลงมานั้น เป็นแรงบันดาลใจสำคัญในการออกแบบแหวนนี้ การจัดเรียงเพชรในลักษณะที่เลียนแบบการไหลของน้ำให้ความรู้สึกถึงการเคลื่อนไหวและชีวิตชีวา</li>
        <li><span class="bolded">การสะท้อนแสง</span>: เพชรที่ถูกจัดเรียงในลักษณะคล้ายหยดน้ำที่สะท้อนแสงจากน้ำตก ทำให้แหวนทรงน้ำตกมีความระยิบระยับและสวยงามมากขึ้น การเลือกใช้เพชรขนาดต่างๆ ที่จัดเรียงให้ดูเหมือนหยดน้ำที่ตกลงมา เพิ่มความสมจริงให้กับการออกแบบ
        </li>
      </div>
    </div>
    </div>
  );
}

export default MainResult;
import React, { useState } from 'react';
import './AddMaker.css';

const AddMaker = () => {
  const [makers, setMakers] = useState({
    maker1: '',
    maker2: '',
    maker3: '',
    maker4: '',
  });

  const handleChange = (event, makerNumber) => {
    setMakers({
      ...makers,
      [makerNumber]: event.target.value,
    });
  };

  return (
    <div className="add-maker-container">

      
      <div className="form-group">
        <label>Maker 1*</label>
        <select className='select-vin'
          value={makers.maker1}
          onChange={(e) => handleChange(e, 'maker1')}
          required
        >
          <option value="">Please select</option>
          <option value="Maker1">Maker 1</option>
          <option value="Maker2">Maker 2</option>
          <option value="Maker3">Maker 3</option>
          <option value="Maker4">Maker 4</option>
        </select>
      </div>

      <div className="form-group">
        <label>Maker 2</label>
        <select className='select-vin'
          value={makers.maker2}
          onChange={(e) => handleChange(e, 'maker2')}
        >
          <option value="">Please select</option>
          <option value="Maker1">Maker 1</option>
          <option value="Maker2">Maker 2</option>
          <option value="Maker3">Maker 3</option>
          <option value="Maker4">Maker 4</option>
        </select>
      </div>

      <div className="form-group">
        <label>Maker 3</label>
        <select className='select-vin'
          value={makers.maker3}
          onChange={(e) => handleChange(e, 'maker3')}
        >
          <option value="">Please select</option>
          <option value="Maker1">Maker 1</option>
          <option value="Maker2">Maker 2</option>
          <option value="Maker3">Maker 3</option>
          <option value="Maker4">Maker 4</option>
        </select>
      </div>

      <div className="form-group">
        <label>Maker 4</label>
        <select className='select-vin'
          value={makers.maker4}
          onChange={(e) => handleChange(e, 'maker4')}
        >
          <option value="">Please select</option>
          <option value="Maker1">Maker 1</option>
          <option value="Maker2">Maker 2</option>
          <option value="Maker3">Maker 3</option>
          <option value="Maker4">Maker 4</option>
        </select>
      </div>
    </div>
  );
};

export default AddMaker;

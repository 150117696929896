import React, { useState } from 'react';
import './AddCertificate.css';

const AddCertificate = () => {
  const [certificates, setCertificates] = useState([
    { name: '', file: null },
  ]);

  // Handle adding a new certificate
  const addCertificate = () => {
    setCertificates([...certificates, { name: '', file: null }]);
  };

  // Handle removing a certificate
  const removeCertificate = (index) => {
    const newCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(newCertificates);
  };

  // Handle input change for name and file
  const handleChange = (index, field, value) => {
    const newCertificates = [...certificates];
    newCertificates[index][field] = value;
    setCertificates(newCertificates);
  };

  return (
    <div className="add-certificate-container">
      {/* <h3>Add Certificate</h3> */}
      {certificates.map((certificate, index) => (
        <div key={index} className="certificate-row">
          <div className="form-group">
            <label>Certificate Name</label>
            <input
              type="text"
              placeholder="Enter certificate name"
              value={certificate.name}
              onChange={(e) =>
                handleChange(index, 'name', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>Upload File</label>
            <input
              type="file"
              onChange={(e) =>
                handleChange(index, 'file', e.target.files[0])
              }
            />
          </div>
          <button className="remove-btn" onClick={() => removeCertificate(index)}>
            Remove
          </button>
        </div>
      ))}
      <button className="add-btn" onClick={addCertificate}>
        Add Another Certificate
      </button>
    </div>
  );
};

export default AddCertificate;

import React from 'react';
import { Link } from 'react-router-dom';
import './BackOfficeLayout.css';
import smileygem from '../../image/smiley_gem_icon.png'

const BackOfficeLayout = ({ children }) => {
  return (
    <div className="backoffice-layout">
      <aside className="sidebar">
        <img src={smileygem} alt="Smiley Gems Logo" className='logo' />
        <nav className="menu">
          <ul>
          <p>Setting</p>
            <li className='tab1'><Link to="/BackOffice/Category">Category</Link></li>
            <li className='tab1'><Link to="/BackOffice/Model">Model</Link></li>
            <li className='tab1'><Link to="/BackOffice/Maker">Maker</Link></li>
          <p>Create</p>
            <li className='tab1'><Link to="/BackOffice/Create/Customs">Customs</Link></li>
            <li className='tab1'><Link to="/BackOffice/Create/Lots">Lots</Link></li>
          <p>List</p>
            <li className='tab1'><Link to="/BackOffice/List/Customs">Customs</Link></li>
            <li className='tab1'><Link to="/BackOffice/List/Lots">Lots</Link></li>
          </ul>
        </nav>
      </aside>
      <main className="content">
        {children}
      </main>
    </div>
  );
}

export default BackOfficeLayout;
